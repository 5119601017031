import React, {useContext, useRef, useState} from "react";
import 'antd/dist/reset.css';

import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';

//Layer
import OlVectorLayer from 'ol/layer/Vector';
//Source
import OlVectorSource from 'ol/source/Vector';
//Format
import OlGeoJSON from 'ol/format/GeoJSON';
//Style
import {Style, Fill, Stroke} from 'ol/style';

import OlFeature from 'ol/Feature';

import {
    Button,
    Col,
    Form,
    Input,
    Modal,
    Row, 
	Drawer 
} from 'antd';

import { DownloadOutlined } from '@ant-design/icons';

import { env } from '../Components/Env'; 
import MapContext from "../Map/MapContext";
import {useSettings} from '../Components/SettingsContext';
import { Coordinate } from "ol/coordinate";

export const selectbplanStyle = new Style({
    fill: new Fill({color: 'rgba(0, 0, 0, 0)'}),
    stroke: new Stroke({
        color: 'rgba(255,0,0,0.6)', 
        width: 8, 
        lineDash: [5, 15, 5, 15]
    })
});		
    
const bplanLayer = new OlVectorLayer ({
    source: new OlVectorSource({
        url: 'Bebauungsplan.geojson',
        format: new OlGeoJSON(),
        attributions: env.attributions
    }),
    style: new Style({
        fill: new Fill({color: 'rgba(0, 0, 0, 0)'}),
        stroke: new Stroke({color: 'rgba(0,0,0,0.6)', width: 3, lineDash: [5, 15, 5, 15]})
    })
});

bplanLayer.set('name', 'Bebaungspläne');
bplanLayer.set('label', 'bplan');
bplanLayer.set('type', 'Polygon');
bplanLayer.set('digi', false);
bplanLayer.set('allow_digi', true);
bplanLayer.set('select', true);
bplanLayer.set('allow_select', true);

interface hthBplanProps {
    closeDrawer: () => void;
    feature: OlFeature | null;
}

const BplanDrawer:React.FC<hthBplanProps> = (props) => {
    const setlog = false;
    setlog && console.log("BplanDrawer:React.FC")

    const map = useContext(MapContext);
    const {settings} = useSettings();
    const [disabled, setDisabled] = useState(true);
    const [touched, setTouched] = useState(false);
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const draggleRef = useRef<HTMLDivElement>(null);

    //const [, forceUpdate] = useReducer(x => x + 1, 0);
  
    const closeDrawer = () =>{
        setlog && console.log(props.feature);
        //setShowDrawer(!showDrawer);
        props.closeDrawer();
    };

    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        setlog && console.log("Start");
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };    

    let getPixelY = ():number => {
        if ( props.feature ){
            if ( props.feature?.get('label')==='bplan' ){
                if ( map ) {
                    const coord:Coordinate = [props.feature?.get('MinX'),props.feature?.get('MinY')];
                    let pixel = map.getPixelFromCoordinate(coord);
                    setlog && console.log('Y',pixel[1]);
                    return pixel![1];
                }
            }
        }
        return 0;       
    };    
    
    if ( !settings.mobile ){
        return ( 
            <Drawer
                title={"Bebauungsplan"}
                className="ggw-bplan"
                placement="right"
                open={props.feature?.get('label')==='bplan'}
                onClose={closeDrawer}
                mask={false}               	 
            >
                <Form layout="vertical">
                    <Row gutter={[8,8]} >					
                        <Col span={24}>
                            <Form.Item style={{ marginBottom: 4 }} label="Nummer">
                                <Input name='nummer' value={props.feature?.get('Nummer')} />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 4 }} label="Bezeichnung">
                                <Input name='bezeichnung' value={props.feature?.get('Bezeichnung')} />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 4 }} label="Rechtsgültig seit">
                                <Input name='rechtsgueltig' value={props.feature?.get('Rechtsgueltig')} />
                            </Form.Item> 
                            <Button type="link" style={{ marginTop: 20 }} href={"../BPlanO/"+props.feature?.get('Original_Pfad')} target="_blank" icon={<DownloadOutlined />} block>Originalplan</Button>
                        </Col>
                    </Row>
                </Form>            
            </Drawer>        
        );
    }
    else {
        return ( 
            <Modal
                title={ <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}                                    
                    onMouseOver={() => {
                        if (disabled) {
                            setlog && console.log("setDisabled(false)");
                            setDisabled(false);
                        }
                    }}
                    onMouseOut={() => {
                        setlog && console.log("setDisabled(true)");
                        setDisabled(true);
                    }}
                    onTouchStart={() => {
                        setlog && console.log("onTouchStart");
                        if (touched) {
                            setDisabled(true);
                        }
                        else{
                            setTouched(false);
                        }                        
                    }}
                    onTouchEnd={() => {
                        setlog && console.log("onTouchEnd");
                        if (disabled) {
                            setlog && console.log("setDisabled(true)");
                            setTouched(true);
                        }   
                        else {
                            setDisabled(false);
                        }                     
                    }}
                    onTouchCancel={() => {
                        setlog && console.log("onTouchCancel");
                        if (!disabled) {
                            setlog && console.log("setDisabled(true)");
                            setDisabled(true);
                        }                                                
                    }}                    
                    onFocus={() => {setlog && console.log("Focus");}}
                    onBlur={() => {setlog && console.log("Blur");}}
                    >
                        Bebauungsplan
                    </div>
                }
                open={props.feature?.get('label')==='bplan'}
                onCancel={closeDrawer}
                footer={[]}
                maskClosable={false}
                style={{top: getPixelY()}}
                modalRender={(modal) => (
                    <Draggable
                        disabled={disabled}
                        bounds={bounds}
                        onStart={(event, uiData) => onStart(event, uiData)}
                    >
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}                   
            >
            <Form layout="vertical">
                    <Row gutter={[8,8]} >					
                        <Col span={24}>
                            <Form.Item style={{ marginBottom: 4 }} label="Nummer">
                                <Input name='nummer' value={props.feature?.get('Nummer')} />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 4 }} label="Bezeichnung">
                                <Input name='bezeichnung' value={props.feature?.get('Bezeichnung')} />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 4 }} label="Rechtsgültig seit">
                                <Input name='rechtsgueltig' value={props.feature?.get('Rechtsgueltig')} />
                            </Form.Item> 
                            <Button type="link" style={{ marginTop: 20 }} href={"../BPlanO/"+props.feature?.get('Original_Pfad')} target="_blank" icon={<DownloadOutlined />} block>Originalplan</Button>
                        </Col>
                    </Row>
                </Form>             
            </Modal>
        );
    }
};


export {bplanLayer,BplanDrawer};