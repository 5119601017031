import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import config from "./Auth0/auth_config.json";
import App from './App';
import {SettingsProvider} from './Components/SettingsContext';

const Auth0App: React.FC = () => {    
    console.log("Auth0App:React.FC");
/*    
    import { useCookies } from 'react-cookie';
    const [cookies, setCookie] = useCookies(['auth0']);

    führt im Production Build zu einer Endlosschleife
    useEffect(() => {
        console.log ('Auth0App: React.FC useEffect[cookies,setCookie]', cookies );
        const auth0Cookie = cookies['auth0'];
  
        if (auth0Cookie && !auth0Cookie.samesite) {
          setCookie('auth0', {
            ...auth0Cookie,
            sameSite: 'none',
          }, {
            secure: true,
          });
        }
    }, [cookies, setCookie]);
*/
    const providerConfig = {
        domain: config.domain,
        clientId: config.clientId,
        useRefreshTokens: true,
        legacySameSiteCookie: false, //reduziert um 2 sameSite Warnungen
        authorizationParams: {
            redirect_uri: window.location.href,
            ...(config.audience ? { audience: config.audience } : null)
        }
    };

    return (
        <Auth0Provider {...providerConfig}>
            <SettingsProvider>
                <App />
            </SettingsProvider>
        </Auth0Provider>
    );
}

export default Auth0App;