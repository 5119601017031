import React, {useContext, useRef, useState} from "react";
import 'antd/dist/reset.css';

import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';

//Layer
import OlVectorLayer from 'ol/layer/Vector';
//Source
import OlVectorSource from 'ol/source/Vector';
//Format
import OlGeoJSON from 'ol/format/GeoJSON';
//Style
import {Style, Icon} from 'ol/style';

import OlFeature from 'ol/Feature';

import {
    Col,
    Form,
    Input,
    Modal,
    Row, 
	Drawer 
} from 'antd';

import { env } from '../Components/Env'; 
import MapContext from "../Map/MapContext";
import {useSettings} from '../Components/SettingsContext';
import { Coordinate } from "ol/coordinate";  
  
  
const selectdefiStyle = new Style({
    image: new Icon({
        scale: [0.08, 0.08],
        anchor: [0.5, 1],
        src: 'sel_defi.png',
    }),
});		
      
const defiLayer = new OlVectorLayer ({
    source: new OlVectorSource({
        url: 'Defibrillatoren.geojson',
        format: new OlGeoJSON(),
        attributions: env.attributions
    }),
    style: new Style({
        image: new Icon({
            scale: [0.08, 0.08],
            anchor: [0.5, 1],
            src: 'defi.png',
        })
    })		
});

defiLayer.set('name', 'Defibrillator');
defiLayer.set('label', 'defi');
defiLayer.set('type', 'Point');
defiLayer.set('digi', false);
defiLayer.set('allow_digi', true);
defiLayer.set('select', true);
defiLayer.set('allow_select', true);

interface defiDrawerProps {
    closeDrawer: () => void;
    feature: OlFeature | null;
}

const DefiDrawer:React.FC<defiDrawerProps> = (props) => {
    const setlog = false;
    setlog && console.log("DefiDrawer:React.FC");

    const map = useContext(MapContext);
    const {settings} = useSettings();
    const [disabled, setDisabled] = useState(true);
    const [touched, setTouched] = useState(false);
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const draggleRef = useRef<HTMLDivElement>(null); 

    const closeDrawer = () =>{
        setlog && console.log("closeDrawer defiDrawer")
        props.closeDrawer();
    };

    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        setlog && console.log("Start");
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };    

    let getPixelY = ():number => {
        if ( props.feature ){
            if ( props.feature?.get('label')==='defi' ){
                if ( map ) {
                    //setlog && console.log (props.feature);
                    const coord:Coordinate = [props.feature?.get('geometry').getExtent()[0],props.feature?.get('geometry').getExtent()[1]];
                    let pixel = map.getPixelFromCoordinate(coord);
                    setlog && console.log('Y',pixel[1]);
                    return pixel![1];
                }
            }
        }
        return 0;       
    };        

    if ( !settings.mobile ){
        return ( 
            <Drawer
                title={"Defibrillator"}
                className="ggw-defi"
                placement="right"
                open={props.feature?.get('label')==='defi'}
                onClose={closeDrawer}
                mask={false}		
            >
                <Form layout="vertical">
                    <Row gutter={[8,8]} >					
                        <Col span={24}>
                            <Form.Item style={{ marginBottom: 4 }} label="Standort">
                                <Input name='standort' value={props.feature?.get('Standort')} />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 4 }} label="Adresse">
                                <Input name='adresse' value={props.feature?.get('Adresse')} />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 4 }} label="Ort">
                                <Input name='plz_ort' value={props.feature?.get('PLZ_Ort')} />
                            </Form.Item>                                        
                        </Col>
                    </Row>
                </Form>            
            </Drawer>
        );
    }
    else {
        return ( 
            <Modal
                title={ <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}                                    
                    onMouseOver={() => {
                        if (disabled) {
                            setlog && console.log("setDisabled(false)");
                            setDisabled(false);
                        }
                    }}
                    onMouseOut={() => {
                        setlog && console.log("setDisabled(true)");
                        setDisabled(true);
                    }}
                    onTouchStart={() => {
                        setlog && console.log("onTouchStart");
                        if (touched) {
                            setDisabled(true);
                        }
                        else{
                            setTouched(false);
                        }                        
                    }}
                    onTouchEnd={() => {
                        setlog && console.log("onTouchEnd");
                        if (disabled) {
                            setlog && console.log("setDisabled(true)");
                            setTouched(true);
                        }   
                        else {
                            setDisabled(false);
                        }                     
                    }}
                    onTouchCancel={() => {
                        setlog && console.log("onTouchCancel");
                        if (!disabled) {
                            setlog && console.log("setDisabled(true)");
                            setDisabled(true);
                        }                                                
                    }}                    
                    onFocus={() => {setlog && console.log("Focus");}}
                    onBlur={() => {setlog && console.log("Blur");}}
                    >
                        Defibrillator
                    </div>
                }
                open={props.feature?.get('label')==='defi'}
                onCancel={closeDrawer}
                footer={[]}
                maskClosable={false}
                style={{top: getPixelY()}}
                modalRender={(modal) => (
                    <Draggable
                        disabled={disabled}
                        bounds={bounds}
                        onStart={(event, uiData) => onStart(event, uiData)}
                    >
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}                   
            >
                 <Form layout="vertical">
                    <Row gutter={[8,8]} >					
                        <Col span={24}>
                            <Form.Item style={{ marginBottom: 4 }} label="Standort">
                                <Input name='standort' value={props.feature?.get('Standort')} />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 4 }} label="Adresse">
                                <Input name='adresse' value={props.feature?.get('Adresse')} />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 4 }} label="Ort">
                                <Input name='plz_ort' value={props.feature?.get('PLZ_Ort')} />
                            </Form.Item>                                        
                        </Col>
                    </Row>
                </Form>            
            </Modal>
        );
    }        
};

export {defiLayer, selectdefiStyle, DefiDrawer};
//export const MemoizeddefiDrawer = React.memo(defiDrawer);