import React, { useContext, useState } from "react";

import { VideoCameraOutlined } from '@ant-design/icons';
import { env } from '../Components/Env';

//Layer
import OlLayerTile from 'ol/layer/Tile';
//Source
import OlTileWMSSource from 'ol/source/TileWMS';

import SimpleButton from "../Components/UI/SimpleButton"
import MapContext from "../Map/MapContext";

const dopLayer = new OlLayerTile({
    source: new OlTileWMSSource({
        url: env.dop.url,
        params: {'LAYERS': env.dop.layer,'TILED': true},
        projection: 'EPSG:25832',
        serverType: env.dop.serverType,
    }),   
    visible: false
});	

dopLayer.set('name', 'Luftbilder');
dopLayer.set('label', 'dop');
dopLayer.set('digi', false);
dopLayer.set('select', false);


interface dopProps {
    updateMap: () => void;
    style: React.CSSProperties;
};

const DOP:React.FC<dopProps> = (props) => {  
    
    const map = useContext(MapContext);
    const [open, setOpen] = useState<boolean>(false);
    const [openOSM, setOpenOSM] = useState<boolean>(false);

    //const [, forceUpdate] = useReducer(x => x + 1, 0);

    const openCloseDOP = () =>{       
        if ( !open ) {
            console.log('openDOP');
            dopLayer.setVisible(true);
            map?.getAllLayers().forEach((layer) => {
                if ( layer.get('label') === 'osm' ){
                    setOpenOSM(layer.getVisible());
                    layer.setVisible(false);
                }
            });
            setOpen(true);
        }
        else {
            console.log('closeDOP');
            dopLayer.setVisible(false);
            map?.getAllLayers().forEach((layer) => {
                if ( layer.get('label') === 'osm' ){
                    if ( openOSM ) {
                        layer.setVisible(true);
                    }
                }
            });            
            setOpen(false);
        }
        //forceUpdate();
        props.updateMap();
    };        

    return (
        <div>            
            <SimpleButton
                type="default"
                style={props.style} 
                shape="circle"
                onClick={openCloseDOP}
                tooltip="Luftbilder ( Ein / Aus )" 
                tooltipPlacement="right"
                icon={<VideoCameraOutlined rotate={90}/>}
            />
            
        </div>
    );
};

export {dopLayer, DOP};