import React, {useContext, useEffect, useRef, useState} from "react";
import 'antd/dist/reset.css';

import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';

//Layer
import OlVectorLayer from 'ol/layer/Vector';
//Source
import OlVectorSource from 'ol/source/Vector';
//Format
import OlGeoJSON from 'ol/format/GeoJSON';
//Style
import {Style, Icon} from 'ol/style';

import OlFeature from 'ol/Feature';

import {
    //App,
    Button,
    Carousel,
    Col,
    Form,
    Image,
    Input,
    message,
    Modal,
    Popconfirm,
    Row,
    Space,
    Upload, 
	Drawer 
} from 'antd';

import { 
    DeleteOutlined,
    QuestionCircleOutlined,
    UploadOutlined 
} from '@ant-design/icons';

import ImgCrop from 'antd-img-crop';
import type { RcFile, UploadFile, UploadProps, UploadChangeParam } from 'antd/es/upload/interface';
import type { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';


import { env , getAttribute } from '../Components/Env'; 
import MapContext from "../Map/MapContext";
import {useSettings} from '../Components/SettingsContext';
import { Coordinate } from "ol/coordinate";  
//import { Geometry } from "ol/geom";
  
import { useAuth0 } from '@auth0/auth0-react';

import { uploadDokumentePG, downloadDokumentePG, deleteDokumentePG } from '../Postgres/pg';

const selecttswtStyle = (feature: OlFeature): Style =>{    
    var typ = feature.get('typ');
    var style:Style;

    switch (typ) {
        case 'Wanne':
            style = new Style({
                image: new Icon({
                    scale: [0.12, 0.12],
                    anchor: [0.5, 1],
                    src: 'sel_fuchs.png',
                }),
            });
        break;
        case 'Schale':
            style = new Style({
                image: new Icon({
                    scale: [0.12, 0.12],
                    anchor: [0.5, 1],
                    src: 'sel_biene.png',
                }),
            });
        break;
        case 'Futter':
            style = new Style({
                image: new Icon({
                    scale: [0.12, 0.12],
                    anchor: [0.5, 1],
                    src: 'sel_vogel.png',
                }),
            });
        break;
        default:
            style = new Style({
                image: new Icon({
                    scale: [0.12, 0.12],
                    anchor: [0.5, 1],
                    src: 'sel_vogel.png',
                }),
            });
        break;        
    } 
    return style;
}

//function tswtStyle(feature: OlFeature): Style {
const tswtStyle = (feature: OlFeature): Style =>{    
    var typ = feature.get('typ');
    var style:Style;

    switch (typ) {
        case 'Wanne':
            style = new Style({
                image: new Icon({
                    scale: [0.12, 0.12],
                    anchor: [0.5, 1],
                    src: 'fuchs.png',
                }),
            });
        break;
        case 'Schale':
            style = new Style({
                image: new Icon({
                    scale: [0.12, 0.12],
                    anchor: [0.5, 1],
                    src: 'biene.png',
                }),
            });
        break;
        case 'Futter':
            style = new Style({
                image: new Icon({
                    scale: [0.12, 0.12],
                    anchor: [0.5, 1],
                    src: 'vogel.png',
                }),
            });
        break;
        default:
            style = new Style({
                image: new Icon({
                    scale: [0.12, 0.12],
                    anchor: [0.5, 1],
                    src: 'vogel.png',
                }),
            });
        break;        
    } 
    return style;
}
      
const tswtLayer = new OlVectorLayer ({
    source: new OlVectorSource({
        //url: 'tswt.geojson',
        format: new OlGeoJSON(),
        attributions: env.attributions
    }),
    style: (feature) => tswtStyle(feature as OlFeature)

});

tswtLayer.set('name', 'Trinkstation');
tswtLayer.set('label', 'tswt');
tswtLayer.set('type', 'Point');
tswtLayer.set('digi', false);
tswtLayer.set('allow_digi', true);
tswtLayer.set('select', true);
tswtLayer.set('allow_select', true);

interface tswtDrawerProps {
    closeDrawer: (unclick:Boolean,action:string) => void;
    feature: OlFeature | null;
}  

const TswtDrawer:React.FC<tswtDrawerProps> = (props) => {
    const setlog = true;
    setlog && console.log("tswtDrawer:React.FC");

    const map = useContext(MapContext);
    const {settings} = useSettings();
    const [disabled, setDisabled] = useState(true);
    const [touched, setTouched] = useState(false);
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const draggleRef = useRef<HTMLDivElement>(null); 
  
    const [attribute, setAttribute] = useState<{[key: string]: any}>({});
    const [dokumente, setDocumente] = useState<Array<{index:number, datei:string}>>([]);
    const [indexDokument, setIndexDokument] = useState(-1);
    const [carousel, setCarousel] = useState(true);

    const attributList = getAttribute(env.tswt?.attribute);
        
    const {
        isAuthenticated,
        getAccessTokenSilently
    } = useAuth0();

    //const { notification } = App.useApp();
    
    const insertDokument = (index:number, datei:string) =>{
        setlog && console.log("TswtDrawer:React.FC insertDokument", index);
        message.success('Das Bild wurde erfolgreich geladen!');
        setDocumente([...dokumente,{index:index, datei:datei}]);
    };

    const uploadDokument = async ( options: RcCustomRequestOptions): Promise<void> => {
        setlog && console.log("TswtDrawer:React.FC uploadDokument", options);
        uploadDokumentePG(props.feature!,getAccessTokenSilently,options.file,insertDokument);
    };

    const setDokument = (current:number) =>{
        setlog && console.log("TswtDrawer:React.FC setDokument", current);
        setIndexDokument(current);
    };

    const deleteDokument = () =>{
        setlog && console.log("TswtDrawer:React.FC deleteDokument",indexDokument);
        if ( indexDokument !== -1){
            const index = dokumente[indexDokument].index;
            deleteDokumentePG(props.feature!,getAccessTokenSilently,index);
            setDocumente(dokumente.filter(item => item.index !== index));
        }
    };

    const onPreview = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
          src = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj as RcFile);
            reader.onload = () => resolve(reader.result as string);
          });
        }
        const image = new (window as any).Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
      };    


    const onChange = (info: UploadChangeParam) => {
        setlog && console.log("TswtDrawer:React.FC onChange",info);
    };

    const propsUpload: UploadProps = {
        showUploadList: false,
        customRequest: uploadDokument,
        onPreview: onPreview,
        listType: 'picture',
        onChange: onChange,
    };

    const propsImgCrop = {
        modalTitle: 'Bild bearbeiten',
        modalOk: '...go!',
        modalCancel: 'Abbrechen',
        resetText: 'Zurücksetzen',
        showGrid: true,
        rotationSlider: true,
        aspectSlider: true,
        showReset: true,
    };

    const updateDokumente = (data:any) =>{
        setlog && console.log("TswtDrawer:React.FC updateDokumente", data);
        setIndexDokument(0);
        setDocumente(data);
    };

    useEffect(() => {
        setlog && console.log("TswtDrawer:React.FC useEffect.[props.feature]", props.feature);
  
        setDocumente([]);
        setIndexDokument(-1);
        downloadDokumentePG(props.feature!,updateDokumente);

        attributList.forEach((a) => {
            setAttribute( prevAttribute => { return {...prevAttribute, [a]:props.feature?.get(a)}});
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.feature]);    

    const dboSave = () =>{
        setlog && console.log("TswtDrawer:React.FC dboSave");

        if ( isAuthenticated ){
            attributList.forEach((a) => {
                props.feature?.set(a,attribute[a]);
            });
                          
            props.closeDrawer(true,'save');
        }
        else {
            props.closeDrawer(true,'');
        }
    };

    const closeDrawer = () =>{
        setlog && console.log("TswtDrawer:React.FC closeDrawer x");
        setDocumente([]);
        setIndexDokument(-1);
        props.closeDrawer(true,'');
    };

    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        //setlog && console.log("TswtDrawer:React.FC omStart");
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };    

    let getPixelY = ():number => {
        if ( props.feature ){
            if ( props.feature?.get('label')==='tswt' ){
                if ( map ) {
                    //setlog && console.log (props.feature);
                    const coord:Coordinate = [props.feature?.get('geometry').getExtent()[0],props.feature?.get('geometry').getExtent()[1]];
                    let pixel = map.getPixelFromCoordinate(coord);
                    //setlog && console.log('Y',pixel[1]);
                    return pixel![1];
                }
            }
        }
        return 0;       
    };

    const dboDeleteReally = () =>{
        setlog && console.log("TswtDrawer:React.FC dboDeleteReally");
        if ( isAuthenticated ){
            setlog && console.log("TswtDrawer:React.FC dboDeleteReally",attribute);
            props.closeDrawer(true,'delete');
        }
        else {
            props.closeDrawer(true,'');
        }            
    };


    const changeString = (key:string, value:string) =>{
        //setlog && console.log("TswtDrawer:React.FC changeString", value);
        setAttribute( currentState => { return {...currentState, [key]:value}});
    };
        
    if ( !settings.mobile ){
        return ( 
            <Drawer
                title={"Trinkstation "+props.feature?.get('index')}
                className="ggw-tswt"
                placement="right"
                open={props.feature?.get('label')==='tswt'}
                onClose={closeDrawer}
                mask={false}
				footer={
                    isAuthenticated && (
                        <Row gutter={[8,8]} >
                            <Col span={12}>
                                <Button onClick={dboSave} type="primary" block>Speichern</Button>	
                            </Col>
                            <Col span={12}>
                                <Popconfirm 
                                    onConfirm={dboDeleteReally}
                                    title={"Wollen Sie die Trinkstaionen wirklich löschen?"} 
                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                    okText="...go!"
                                    okType="danger"
                                    cancelText="Nein"
                                >
                                    <Button type="link" block>Löschen</Button>
                                </Popconfirm>						
                            </Col>
                        </Row>
                    )
				}                                
            >
                <Form layout="vertical">
                    <Row gutter={[8,8]} >					
                        <Col span={24}>
                            { attributList.map((a,i) => (
                                <Form.Item key={'form'+i} style={{ marginBottom: 4 }} label={a.charAt(0).toUpperCase() + a.slice(1)}>
                                    <Input key={'input'+i} name={a} value={attribute[a]} onChange={e => changeString(a,e.target.value)} />
                                </Form.Item>                                    
                            ))}

                            <Form.Item style={{ marginBottom: 4 }} label={"Bilder (" + dokumente.length  + ")"}>
                                {dokumente.length === 0 ? (
                                    <a id="tswt_mailto" href={"mailto:tswt-maintal@gisgoweb.de?Subject=Trinkstation für Wildtiere: " +props.feature?.get('index') }>
                                        <Image
                                            preview = {false}
                                            key = 'image_default'
                                            src = "./tswt/default.jpg"
                                        />
                                    </a>
                                ) : (
                                    <Image.PreviewGroup>
                                        <Carousel autoplay={carousel} effect="fade" infinite={false} afterChange={setDokument}>
                                            { dokumente.map((element) => (
                                                <Image
                                                    key = {'image'+element.index}
                                                    src = { "./tswt/" + element.datei}
                                                    fallback = "./tswt/default.jpg"
                                                />
                                            ))}
                                        </Carousel>
                                    </Image.PreviewGroup>
                                )}                                               
                            </Form.Item>

                            { isAuthenticated && (
                                <Space>
                                    <ImgCrop {...propsImgCrop}>
                                        <Upload {...propsUpload}>
                                            <Button icon={<UploadOutlined />} />             
                                        </Upload>
                                    </ImgCrop>


                                    <Popconfirm
                                        onPopupClick={() => setCarousel(true)} 
                                        onConfirm={deleteDokument}
                                        title={"Wollen Sie das Bild wirklich löschen?"} 
                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                        okText="...go!"
                                        okType="danger"
                                        cancelText="Nein"
                                    >
                                        <Button icon={<DeleteOutlined />} onClick={() => setCarousel(false)}  />
                                    </Popconfirm>						
                                </Space>
                            )}
                        </Col>
                    </Row>
                </Form>            
            </Drawer>
        );
    }
    else {
        return ( 
            <Modal
                title={ <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}                                    
                    onMouseOver={() => {
                        if (disabled) {
                            //setlog && console.log("setDisabled(false)");
                            setDisabled(false);
                        }
                    }}
                    onMouseOut={() => {
                        //setlog && console.log("setDisabled(true)");
                        setDisabled(true);
                    }}
                    onTouchStart={() => {
                        //setlog && console.log("onTouchStart");
                        if (touched) {
                            setDisabled(true);
                        }
                        else{
                            setTouched(false);
                        }                        
                    }}
                    onTouchEnd={() => {
                        //setlog && console.log("onTouchEnd");
                        if (disabled) {
                            //setlog && console.log("setDisabled(true)");
                            setTouched(true);
                        }   
                        else {
                            setDisabled(false);
                        }                     
                    }}
                    onTouchCancel={() => {
                        //setlog && console.log("onTouchCancel");
                        if (!disabled) {
                            //setlog && console.log("setDisabled(true)");
                            setDisabled(true);
                        }                                                
                    }}              
                    /*      
                    onFocus={() => {setlog && console.log("Focus");}}
                    onBlur={() => {setlog && console.log("Blur");}}
                    */
                    >
                        Trinkstation {props.feature?.get('index')}
                    </div>
                }
                open={props.feature?.get('label')==='tswt'}
                onCancel={closeDrawer}
                footer={[                  
                    isAuthenticated && (
                        <Row key='f_r' gutter={[8,8]} >
                            <Col key='f_c1' span={12}>
                                <Button key='f_save' onClick={dboSave} type="primary" block>Speichern</Button>	
                            </Col>
                            <Col key='f_c2' span={12}>
                                <Popconfirm
                                    key='popconfirm' 
                                    onConfirm={dboDeleteReally}
                                    title={"Wollen Sie die Trinkstaionen wirklich löschen?"} 
                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                    okText="...go!"
                                    okType="danger"
                                    cancelText="Nein"
                                >
                                    <Button key='f_delete' type="link" block>Löschen</Button>
                                </Popconfirm>						
                            </Col>
                        </Row>
                    )
                ]}
                maskClosable={false}
                style={{top: getPixelY()}}
                modalRender={(modal) => (
                    <Draggable
                        disabled={disabled}
                        bounds={bounds}
                        onStart={(event, uiData) => onStart(event, uiData)}
                    >
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}                   
            >
                <Form layout="vertical">
                    <Row gutter={[8,8]} >					
                        <Col span={24}>
                            { attributList.map((a,i) => (
                                <Form.Item key={'form'+i} style={{ marginBottom: 4 }} label={a.charAt(0).toUpperCase() + a.slice(1)}>
                                    <Input key={'input'+i} name={a} value={attribute[a]} onChange={e => changeString(a,e.target.value)} />
                                </Form.Item>                                    
                            ))}

                            <Form.Item style={{ marginBottom: 4 }} label={"Bilder (" + dokumente.length  + ")"}>
                                {dokumente.length === 0 ? (
                                    <a id="tswt_mailto" href={"mailto:tswt-maintal@gisgoweb.de?Subject=Trinkstation für Wildtiere: " +props.feature?.get('index') }>
                                        <Image
                                            preview = {false}
                                            key = 'image_default'
                                            src = "./tswt/default.jpg"
                                        />
                                    </a>
                                ) : (
                                    <Image.PreviewGroup>
                                        <Carousel autoplay={carousel} effect="fade" infinite={false} afterChange={setDokument}>
                                            { dokumente.map((element) => (
                                                <Image
                                                    key = {'image'+element.index}
                                                    src = { "./tswt/" + element.datei}
                                                    fallback = "./tswt/default.jpg"
                                                />
                                            ))}
                                        </Carousel>
                                    </Image.PreviewGroup>
                                )}                                               
                            </Form.Item>

                            { isAuthenticated && (
                                <Space>
                                    <ImgCrop {...propsImgCrop}>
                                        <Upload {...propsUpload}>
                                            <Button icon={<UploadOutlined />} />             
                                        </Upload>
                                    </ImgCrop>


                                    <Popconfirm
                                        onPopupClick={() => setCarousel(true)} 
                                        onConfirm={deleteDokument}
                                        title={"Wollen Sie das Bild wirklich löschen?"} 
                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                        okText="...go!"
                                        okType="danger"
                                        cancelText="Nein"
                                    >
                                        <Button icon={<DeleteOutlined />} onClick={() => setCarousel(false)}  />
                                    </Popconfirm>						
                                </Space>
                            )}                            
                        </Col>
                    </Row>
                </Form>            
            </Modal>
        );
    }        
};

export {tswtLayer, selecttswtStyle, TswtDrawer};
//export const MemoizedtswtDrawer = React.memo(TswtDrawer);