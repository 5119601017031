//import React, {useEffect} from 'react';

import OlSourceOsm from 'ol/source/OSM';
import OlLayerTile from 'ol/layer/Tile';

const osmLayer = new OlLayerTile({
    visible: true,
    source: new OlSourceOsm({
        attributions: ['<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> | ']
    })
});

osmLayer.set('name', 'Open Street Map');
osmLayer.set('label', 'osm');
osmLayer.set('digi', false);
osmLayer.set('allow_digi', false);
osmLayer.set('select', false);
osmLayer.set('allow_select', false);

export {osmLayer};