import React, {useContext, useReducer} from 'react';
import { getCookie, setCookie, removeCookie } from 'typescript-cookie'

type SettingsProviderProps = {children: React.ReactNode};
type Action = {type: 'mobile' } | 
              {type: 'cookies' } | 
              {type: 'projection', nextStr: string} | 
              {type: 'digi', nextStr: string} | 
              {type: 'testNum', nextNum: number } | 
              {type: 'testStr', nextStr: string};
type Dispatch = (action: Action) => void;
type Settings = {mobile: boolean, cookies: boolean, projection: string, digi: string, testNum: number, testStr: string};

const SettingsContext = React.createContext<
    {settings: Settings; setSettings: Dispatch} | undefined
  >(undefined);

const settingsReducer = (settings: Settings, action: Action) => {  
  switch (action.type) {
    case 'mobile': {
      settings.cookies ? setCookie('mobile',!settings.mobile, { sameSite: 'none', expires: 7 }) : removeCookie('mobile');
      return {
        ...settings,
        mobile: !settings.mobile, 
      };
    }
    case 'projection': {
      //console.log(action.nextStr);
      settings.cookies ? setCookie('projection',action.nextStr, { sameSite: 'none', expires: 7 }) : removeCookie('projection');
      return {
        ...settings,
        projection: action.nextStr,
      };
    }    
    case 'digi': {
      settings.cookies ? setCookie('digi',action.nextStr, { sameSite: 'none', expires: 7 }) : removeCookie('digi');
      return {
        ...settings,
        digi: action.nextStr,
      };
    }
    case 'cookies': {
      if ( settings.cookies === true ){
        removeCookie('cookies');
        removeCookie('mobile');
        removeCookie('projection');
        removeCookie('digi');
      }
      else {
        setCookie('cookies','true', { sameSite: 'none', expires: 7 });
        setCookie('mobile',settings.mobile, { sameSite: 'none', expires: 7 });
        setCookie('projection',settings.projection, { sameSite: 'none' , expires: 7 });
        setCookie('digi',settings.digi, { sameSite: 'none' , expires: 7 });
      }
      return {
        ...settings,
        cookies: !settings.cookies, 
      };
    }    
    case 'testNum': {
      return {
        ...settings,
        testNum: action.nextNum
      };
    }    
    case 'testStr': {
      return {
        ...settings,
        testStr: action.nextStr
      };
    }        
    default: {
      throw new Error('Unhandled action type');
      //throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
};

const SettingsProvider = ({children}: SettingsProviderProps) => {  

  let cookies:string|undefined;
  cookies = getCookie('cookies');

  let mobile:string|undefined;
  mobile = getCookie('mobile');

  let projection:string|undefined;
  projection = getCookie('projection');
  //console.log("SettingsProvider :",projection);
  
  let digi:string|undefined;
  digi = getCookie('digi');

  const initialState: Settings = {
    cookies: cookies === 'true' ?  true : false,
    mobile: mobile === 'true' ?  true : false, 
    projection: projection === 'EPSG:4326' ? 'EPSG:4326' : 'EPSG:25832',
    digi: digi === undefined ? '' : digi,
    testNum: 0, 
    testStr: ''
  };

  const [settings, setSettings] = useReducer(settingsReducer, initialState);
  const value = {settings, setSettings};
  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};

const useSettings = () => {  
  //custom-react-hook
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
};

export {SettingsProvider,useSettings};