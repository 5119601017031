//Layer
import OlVectorLayer from 'ol/layer/Vector';
import OlSourceVector  from 'ol/source/Vector';

//Style
import {Fill, Stroke, Style, Circle} from 'ol/style';

     
const selectLayer = new OlVectorLayer({
    source: new OlSourceVector(),
    style: new Style({
        fill: new Fill({
            color: 'rgba(255, 255, 255, 0.05)',
        }),
        stroke: new Stroke({
            color: 'rgb(255,0,0)',
            width: 4,
        }),
        image: new Circle({
            radius: 20,
            fill: new Fill({
                color: 'rgba(255,0,0,0.3)', 
            }),
        })			
    })			        
});

selectLayer.set('name', 'Overlay');
selectLayer.set('label', '');
selectLayer.set('digi', false);
selectLayer.set('select', false);

export {selectLayer};