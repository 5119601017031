//Layer
import OlImageLayer from 'ol/layer/Image';
     
const imageLayer = new OlImageLayer({
    opacity: 0.6,
});

imageLayer.set('name', 'Overlay');
imageLayer.set('label', '');
imageLayer.set('digi', false);
imageLayer.set('select', false);

export {imageLayer};