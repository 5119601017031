import OlVectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import OlFeature from 'ol/Feature';
import WKT from 'ol/format/WKT';

import { envProps } from '../Components/EnvProps';
import { env , getAttribute } from '../Components/Env';

//import type { RcFile } from 'antd/es/upload/interface';

//import { GetTokenSilentlyOptions, GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';
// das mit dem any ist nicht schön!!!
//getTokenSilently(options: GetTokenSilentlyOptions & {detailedResponse: true;}): Promise<GetTokenSilentlyVerboseResponse>

import {
    notification
} from 'antd';

const setlog = true;

const getAllPG = async (server:string, database:string, schema:string, tabelle:string, attribute:string, vector:OlVectorSource) => {
    setlog && console.log('Postgres getAllPG');

    let headers = new Headers();   
    
    headers.append('Content-Type','application/json');
    headers.append('Access-Control-Allow-Origin', '*');		

    attribute = 'index,' + attribute;
    
    fetch( server + `/` + database + `/` + schema + `/getAllPG`, {
        headers: headers,
        method: 'POST',
        credentials: 'omit', // vorher include
        body: JSON.stringify({tabelle, attribute}),		  
    })
   .then(response => response.json())
   .then(data => {
        if ( data.success ){			   
            if ( data.data.length > 0){
                vector.addFeatures(new GeoJSON().readFeatures(data.data[0].row_to_json));
                console.log("Geladen:", schema);
            }
            else{
                notification.open({
                    message: 'Info',
                    description: 'Die Tabelle ist leer!',
                    placement: 'topLeft',
                    type: 'info',
                });				   
            }
        }
        else{
            notification.open({
                message: 'Fehler',
                description: 'Beim Abruf der Tabelle ist ein Fehler aufgetreten!',
                placement: 'topLeft',
                type: 'error',
            });				   			   
            console.log(data);
        }
   })
  .catch(error => {
   });
};

const savePG = async (feature:OlFeature,getAccessTokenSilently:() => any) => {
    setlog && console.log('Postgres savePG ', feature);

    const tabelle:string = feature?.get('label');
    
    if ( tabelle ){
        if ( env.hasOwnProperty(tabelle) ) {
            const featureEnv:any = env[tabelle as keyof envProps];
            if ( featureEnv ){
                setlog && console.log('Postgres savePG',featureEnv);

                let headers = new Headers();

                const token = await getAccessTokenSilently();
                headers.append('authorization', 'Bearer ' + token);	
                headers.append('Content-Type','application/json');		
                
                let body: { [t: string]: string } = {};
                               
                const server = featureEnv.url;
                const database =featureEnv.database;
                const schema = featureEnv.schema;
                body['tabelle'] = tabelle;
                body['index'] = feature?.get('index');

                const attributList = getAttribute(featureEnv?.attribute);
  
                attributList.forEach((a) => {
                    body[a] = feature?.get(a);
                });
                           
                fetch( server + `/` + database + `/` + schema + `/updatePG`, {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify(body),		  
                })
                .then(response => response.json())
                .then(data => {
                    if ( data.success ){
                        setlog && console.log('Postgres savePG OK',data);                               
                    }
                    else{
                        alert("Beim Speichern ist ein Fehler aufgetreten");
                        setlog && console.log('Postgres savePG Fehler',data);
                    }
                })
                .catch(error => {
                });
            }   
        }    
    }
    else {
        setlog && console.log('Postgres Fehler in savePG');
    }
}

const deletePG = async (feature:OlFeature,getAccessTokenSilently:() => any, removeFeature:(feature: OlFeature, tabelle: string) => void) => {
    setlog && console.log('Postgres deletePG ', feature);

    const tabelle:string = feature?.get('label');
    
    if ( tabelle ){
        if ( env.hasOwnProperty(tabelle) ) {
            const featureEnv:any = env[tabelle as keyof envProps];
            if ( featureEnv ){
                setlog && console.log('Postgres deletePG',featureEnv);

                let headers = new Headers();
                            
                const token = await getAccessTokenSilently();
                headers.append('authorization', 'Bearer ' + token);	
                headers.append('Content-Type','application/json');		
                
                let body: { [t: string]: string } = {};
                               
                const server = featureEnv.url;
                const database =featureEnv.database;
                const schema = featureEnv.schema;
                body['tabelle'] = tabelle;
                body['index'] = feature?.get('index');

                           
                fetch( server + `/` + database + `/` + schema + `/deletePG`, {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify(body),		  
                })
                .then(response => response.json())
                .then(data => {
                    if ( data.success ){
                        setlog && console.log('Postgres deletePG OK');
                        removeFeature(feature!,tabelle);
                    }
                    else{
                        alert("Beim Speichern ist ein Fehler aufgetreten");
                        setlog && console.log('Postgres deletePG Fehler',data);
                    }
                })
                .catch(error => {
                });
            }   
        }    
    }
    else {
        setlog && console.log('Postgres Fehler in deletePG');
    }
}

const newPG = async (feature:OlFeature,getAccessTokenSilently:() => any) => {
    setlog && console.log('Postgres newPG',feature);

    const tabelle:string = feature?.get('label');
    
    if ( tabelle ){
        if ( env.hasOwnProperty(tabelle) ) {
            const featureEnv:any = env[tabelle as keyof envProps];
            if ( featureEnv ){
                //setlog && console.log('Postgres newPG',featureEnv);

                let headers = new Headers();
                            
                const token = await getAccessTokenSilently();
                headers.append('authorization', 'Bearer ' + token);	
                headers.append('Content-Type','application/json');		
                
                let body: { [t: string]: string } = {};
                               
                const server = featureEnv.url;
                const database =featureEnv.database;
                const schema = featureEnv.schema;
                body['tabelle'] = tabelle;

                var format = new WKT();
                var geometry = feature.getGeometry();
                var geometryString = format.writeGeometry(geometry!);

                body['geometrie']=geometryString;
                
                //setlog && console.log('Postgres newPG ',geometry);
                //setlog && console.log('Postgres newPG ',geometryString);

                fetch( server + `/` + database + `/` + schema + `/insertPG`, {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify(body),		  
                })
                .then(response => response.json())
                .then(data => {
                    if ( data.success ){
                        setlog && console.log('Postgres newPG OK',data);
                        feature.set('index', data.data.index);                               
                    }
                    else{
                        alert("Beim Erstellen ist ein Fehler aufgetreten");
                        setlog && console.log('Postgres newPG Fehler',data);
                    }
                })
                .catch(error => {
                });
            }   
        }    
    }
    else {
        setlog && console.log('Postgres Fehler in newPG');
    }
};

const uploadDokumentePG = async (feature:OlFeature,getAccessTokenSilently:() => any,file:any,insertDokument:(index:number, datei:string) => any) => {
    setlog && console.log('Postgres uploadDokumentePG',feature);

    const tabelle:string = feature?.get('label');
    if ( tabelle ){
        if ( env.hasOwnProperty(tabelle) ) {
            const featureEnv:any = env[tabelle as keyof envProps];
            if ( featureEnv ){
                if ( featureEnv.dokumente ){
                
                    /*
                    if (file instanceof Blob) {
                        console.log('file ist ein Blob:', file);
                    } else {
                        console.log('file ist kein Blob:', file);
                    }
                    */

                    setlog && console.log('Postgres uploadDokumentePG',file);

                    let headers = new Headers();
                                
                    const token = await getAccessTokenSilently();
                    headers.append('authorization', 'Bearer ' + token);	
                                                    
                    const server = featureEnv.url;
                    const database = featureEnv.database;
                    const schema = featureEnv.schema;
                    
                    var formData = new FormData();
                    
                    formData.append('tabelle', tabelle+'_dokumente');
                    formData.append('index', feature?.get('index'));
                    formData.append('filename', file.name);
                    formData.append('file', file as Blob);


                    fetch( server + `/` + database + `/` + schema + `/uploadPG`, {
                        headers: headers,
                        method: 'POST',
                        body: formData,		  
                    })
                    .then(response => response.json())
                    .then(data => {
                        if ( data.success ){
                            setlog && console.log('Postgres uploadDokumentePG OK',data.data.index);
                            insertDokument(data.data.index,file.name);                               
                        }
                        else{
                            alert("Beim Hochladen der Datei ist ein Fehler aufgetreten");
                            setlog && console.log('Postgres uploadDokumentePG Fehler',data);
                        }
                    })
                    .catch(error => {
                    });
                }
                else{
                    setlog && console.log('Postgres Fehler in uploadDokumentePG - keine Dokumente');
                }
            }
        }
    }       
    else {
        setlog && console.log('Postgres Fehler in uploadDokumentePG');
    }
};

const downloadDokumentePG = async (feature:OlFeature,updateDokumente:(data:any) => any) => {
    setlog && console.log('Postgres downloadDokumentePG');

    const tabelle:string = feature?.get('label');

    if ( tabelle ){
        if ( env.hasOwnProperty(tabelle) ) {
            const featureEnv:any = env[tabelle as keyof envProps];
            if ( featureEnv ){
                setlog && console.log('Postgres downloadDokumentePG',featureEnv);

                let headers = new Headers();
                            
                headers.append('Content-Type','application/json');		
                
                let body: { [t: string]: string } = {};
                               
                const server = featureEnv.url;
                const database =featureEnv.database;
                const schema = featureEnv.schema;
                body['tabelle'] = tabelle;
                body['index'] = feature?.get('index');
                body['attribute'] = 'index,datei';
    
                fetch( server + `/` + database + `/` + schema + `/getDokumentePG`, {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify(body),		  
                })
                .then(response => response.json())
                .then(data => {
                    if ( data.success ){			   
                        if ( data.data.length > 0){
                            updateDokumente( data.data );
                            //console.log("Geladen:", data.data);
                        }
                        else{
                            /*
                            notification.open({
                                message: 'Info',
                                description: 'Die Tabelle ist leer!',
                                placement: 'topLeft',
                                type: 'info',
                            });
                            */
                        }
                    }
                    else{
                        notification.open({
                            message: 'Fehler',
                            description: 'Beim Abruf der Tabelle ist ein Fehler aufgetreten!',
                            placement: 'topLeft',
                            type: 'error',
                        });				   			   
                        console.log(data);
                    }
                })
                .catch(error => {
                });
            }
        }
    }
};

const deleteDokumentePG = async (feature:OlFeature,getAccessTokenSilently:() => any, index:number) => {
    setlog && console.log('Postgres deleteDokumentePG ', feature);

    const tabelle:string = feature?.get('label');
    
    if ( tabelle ){
        if ( env.hasOwnProperty(tabelle) ) {
            const featureEnv:any = env[tabelle as keyof envProps];
            if ( featureEnv ){
                setlog && console.log('Postgres deleteDokumentePG',featureEnv);

                let headers = new Headers();
                            
                const token = await getAccessTokenSilently();
                headers.append('authorization', 'Bearer ' + token);	
                headers.append('Content-Type','application/json');		
                
                let body: { [t: string]: string } = {};
                               
                const server = featureEnv.url;
                const database =featureEnv.database;
                const schema = featureEnv.schema;
                body['tabelle'] = tabelle+'_dokumente';
                body['index'] = String(index);

                           
                fetch( server + `/` + database + `/` + schema + `/deletePG`, {
                    headers: headers,
                    method: 'POST',
                    body: JSON.stringify(body),		  
                })
                .then(response => response.json())
                .then(data => {
                    if ( data.success ){
                        setlog && console.log('Postgres deleteDokumentePG OK');
                        
                    }
                    else{
                        alert("Beim Speichern ist ein Fehler aufgetreten");
                        setlog && console.log('Postgres deletePG Fehler',data);
                    }
                })
                .catch(error => {
                });
            }   
        }    
    }
    else {
        setlog && console.log('Postgres Fehler in deleteDokumentePG');
    }
}




export {getAllPG,savePG,deletePG,newPG,uploadDokumentePG,downloadDokumentePG,deleteDokumentePG};