import React, { useMemo, useState } from 'react';
import { useSearchParams  } from 'react-router-dom';

import Auth0App from './Auth0App';
import Map from './Map/Map';
import {SettingsProvider} from './Components/SettingsContext';

const LoginApp: React.FC = () => {    
    console.log("LoginApp:React.FC");

    const [login, setLogin]= useState(false);
    const [searchParams] = useSearchParams();

    useMemo(() => { //wirdausgeführt bevor die FC gerendert wird
        const log = searchParams.get('login');
        if ( !!log ){        
            setLogin(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    if ( login ){
        return (
            <Auth0App />
        );
    }
    else {
        return (
            <SettingsProvider>
                <Map />
            </SettingsProvider>
        );
    }
}

export default LoginApp;