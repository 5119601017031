import React, {useContext, useRef, useState} from "react";
import 'antd/dist/reset.css';

import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';

//Layer
import OlTileLayer from 'ol/layer/Tile';
//Source
import OlTileWMSSource from 'ol/source/TileWMS';

import OlFeature from 'ol/Feature';

import {
    Col,
    Form,
    Input,
    Modal,
    Row, 
	Drawer 
} from 'antd';

import { env } from '../Components/Env'; 
import MapContext from "../Map/MapContext";
import {useSettings} from '../Components/SettingsContext';
import { Coordinate } from "ol/coordinate";
    
const alkisLayer = new OlTileLayer ({
    source: new OlTileWMSSource({
        url: env.alkis.url,
        params: {'LAYERS': env.alkis.layer,'TILED': true},
		projection: 'EPSG:25832',
		serverType: env.alkis.serverType,
        attributions: ['<a href="https://gds.hessen.de">GDS-Hessen</a> ']
    }),
});

alkisLayer.set('name', 'ALKIS');
alkisLayer.set('label', 'alkis');
alkisLayer.set('digi', true);
alkisLayer.set('allow_digi', false);
alkisLayer.set('select', true);
alkisLayer.set('allow_select', true);
alkisLayer.set('load', false);

interface alkisProps {
    closeDrawer: () => void;
    feature: OlFeature | null;
}

const AlkisDrawer:React.FC<alkisProps> = (props) => {
    const setlog = false;
    setlog && console.log("AlkisDrawer:React.FC");
    const map = useContext(MapContext);
    const {settings} = useSettings();

    const [disabled, setDisabled] = useState(true);
    const [touched, setTouched] = useState(false);
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const draggleRef = useRef<HTMLDivElement>(null);    

    const closeDrawer = () =>{
        setlog && console.log(props.feature);
        props.closeDrawer();
    };

    let getPixelY = ():number => {
        if ( props.feature ){
            if ( props.feature?.get('label')==='alkis' ){
                if ( map ) {
                    //setlog && console.log(props.feature?.get('geometry').getExtent());
                    const coord:Coordinate = [props.feature?.get('geometry').getExtent()[0],props.feature?.get('geometry').getExtent()[1]];
                    let pixel = map.getPixelFromCoordinate(coord);
                    setlog && console.log('Y',pixel[1]);
                    return pixel![1];
                }
            }
        }
        return 0;       
    };        

    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        setlog && console.log("Start");
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };    

    if ( !settings.mobile ){
        return ( 
            <Drawer
                title={"Flurstück"}
                className="ggw-alkis"
                placement="right"
                open={props.feature?.get('label')==='alkis'}
                onClose={closeDrawer}
                mask={false}		 
            >
                <Form layout="vertical">
                    <Row gutter={[8,8]} >					
                        <Col span={24}>
                            <Form.Item style={{ marginBottom: 4 }} label="Gemeinde">
                                <Input name='gemeinde' value={props.feature?.get('gemename')} />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 4 }} label="Gemarkung">
                                <Input name='gemarkung' value={props.feature?.get('gemarkung')} />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 4 }} label="Flur">
                                <Input name='flur' value={props.feature?.get('flur')} />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 4 }} label="Zähler">
                                <Input name='zaehler' value={props.feature?.get('zaehler')} />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 4 }} label="Nenner">
                                <Input name='nenner' value={props.feature?.get('nenner')} />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 4 }} label="Amtliche Fläche (m²)">
                                <Input name='amtflaeche' value={props.feature?.get('amtflaeche')} />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 4 }} label="Tatsächliche Nutzung">
                                <Input name='tn' value={props.feature?.get('tn')} />
                            </Form.Item>                         
                        </Col>
                    </Row>
                </Form>            
            </Drawer>        
        );
    }
    else {
        return ( 
            <Modal
                title={ <div
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}                                    
                    onMouseOver={() => {
                        if (disabled) {
                            setlog && console.log("setDisabled(false)");
                            setDisabled(false);
                        }
                    }}
                    onMouseOut={() => {
                        setlog && console.log("setDisabled(true)");
                        setDisabled(true);
                    }}
                    onTouchStart={() => {
                        setlog && console.log("onTouchStart");
                        if (touched) {
                            setDisabled(true);
                        }
                        else{
                            setTouched(false);
                        }                        
                    }}
                    onTouchEnd={() => {
                        setlog && console.log("onTouchEnd");
                        if (disabled) {
                            setlog && console.log("setDisabled(true)");
                            setTouched(true);
                        }   
                        else {
                            setDisabled(false);
                        }                     
                    }}
                    onTouchCancel={() => {
                        setlog && console.log("onTouchCancel");
                        if (!disabled) {
                            setlog && console.log("setDisabled(true)");
                            setDisabled(true);
                        }                                                
                    }}                    
                    onFocus={() => {setlog && console.log("Focus");}}
                    onBlur={() => {setlog && console.log("Blur");}}
                    >
                        Flurstück
                    </div>
                }
                open={props.feature?.get('label')==='alkis'}
                onCancel={closeDrawer}
                footer={[]}
                maskClosable={false}
                style={{top: getPixelY()}}
                modalRender={(modal) => (
                    <Draggable
                        disabled={disabled}
                        bounds={bounds}
                        onStart={(event, uiData) => onStart(event, uiData)}
                    >
                        <div ref={draggleRef}>{modal}</div>
                    </Draggable>
                )}                   
            >
            <Form layout="vertical">
                    <Row gutter={[8,8]} >					
                        <Col span={24}>
                            <Form.Item style={{ marginBottom: 4 }} label="Gemeinde">
                                <Input name='gemeinde' value={props.feature?.get('gemename')} />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 4 }} label="Gemarkung">
                                <Input name='gemarkung' value={props.feature?.get('gemarkung')} />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 4 }} label="Flur">
                                <Input name='flur' value={props.feature?.get('flur')} />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 4 }} label="Zähler">
                                <Input name='zaehler' value={props.feature?.get('zaehler')} />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 4 }} label="Nenner">
                                <Input name='nenner' value={props.feature?.get('nenner')} />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 4 }} label="Amtliche Fläche (m²)">
                                <Input name='amtflaeche' value={props.feature?.get('amtflaeche')} />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 4 }} label="Tatsächliche Nutzung">
                                <Input name='tn' value={props.feature?.get('tn')} />
                            </Form.Item>                         
                        </Col>
                    </Row>
                </Form>             
            </Modal>
        );
    }
};


export {alkisLayer,AlkisDrawer};