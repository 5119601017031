import React, { useCallback, useContext, useEffect, useState } from "react";

import OlVectorLayer from 'ol/layer/Vector';
import OlSourceVector  from 'ol/source/Vector';

import 'antd/dist/reset.css';

import { FormOutlined  } from '@ant-design/icons';

import Feature, { FeatureLike } from 'ol/Feature';
import Type from 'ol/geom/Geometry';
import type {Type as OlType}  from 'ol/geom/Geometry';
import Polygon from 'ol/geom/Polygon';
import Point from 'ol/geom/Point';
import LineString from 'ol/geom/LineString'
import { Draw as OlDraw, Modify as OlModify, Snap as OlSnap } from 'ol/interaction';
import { DrawEvent } from 'ol/interaction/Draw';
import {Circle as CircleStyle, Fill, RegularShape, Stroke, Style, Text} from 'ol/style';
import {getArea, getLength} from 'ol/sphere';

import { useAuth0 } from '@auth0/auth0-react';

import SimpleButton from "./UI/SimpleButton"
import MapContext from "../Map/MapContext";
import {useSettings} from '../Components/SettingsContext';
import { Geometry  } from "ol/geom";

import { envProps } from '../Components/EnvProps';
import { env , getAttribute } from '../Components/Env';

import {
    Button, 
	Card,
    Checkbox,
	Col, 
	Drawer, 
	Form,
    Modal  ,
	Radio,
	Row } from 'antd';



interface measureProps {
    clickControl: (open:boolean) => void;
    newPG: (f:Feature,getAccessTokenSilently:() => any) => void;
    style: React.CSSProperties;
};

const Measure:React.FC<measureProps> = (props) => {  

    const setlog = true;
    setlog && console.log("Measure:React.FC");
    
    const map = useContext(MapContext);
    const {settings} = useSettings();
    const [measureOpen, setMeasureOpen] = useState<boolean>(false);
    const {
        isAuthenticated,
        getAccessTokenSilently
    } = useAuth0();    

    const [source, setSource] = useState<OlSourceVector|undefined>();
    const [vector, setVector] = useState<OlVectorLayer<OlSourceVector<Type>>|undefined>();
    const [modify, setModify] = useState<OlModify|undefined>();
    const [draw, setDraw] = useState<OlDraw|undefined>();
    const [snap, setSnap] = useState<OlSnap|undefined>();    

    const [measureType, setMeasureType] = useState<OlType>('LineString');
    const [showSegments, setShowSegments] = useState<boolean>(true);
    const [digi, setDigi] = useState<boolean>(false);
    const [changeGeo, setChangeGeo] = useState<boolean>(true);
   
    const [clearPrevious, setClearPrevious] = useState<boolean>(false);
    const [clearAll, setClearAll] = useState<boolean>(false);
    const [stayVisible, setStayVisible] = useState<boolean>(false);
    const [snapActive, setSnapActive] = useState<boolean>(true);
    
    const [isDigiOpen, setIsDigiOpen] = useState(false);

    //const [, forceUpdate] = useReducer(x => x + 1, 0);
    
      const digiOk = () => {
        setlog && console.log("Measure:React.FC digiOK", source?.getFeatures());

        map?.getAllLayers().forEach((l) => {
            if ( l.get('label') ){
                if ( l.get('name') === settings.digi ){
                    setlog && console.log("Measure:React.FC digiOK", settings.digi);

                    if (l.getSource() instanceof OlSourceVector) {
                        const targetSource:OlSourceVector = l.getSource() as OlSourceVector;
                        setlog && console.log("Measure:React.FC digiOK", targetSource);

                        const f:Feature|undefined = source?.getFeatures()[0];
                        targetSource.addFeature(f!);

                        f?.setProperties({index: -1});
                        f?.setProperties({label: l.get('label')});
                        
                        if ( env.hasOwnProperty(l.get('label')) ) {
                            const featureEnv:any = env[l.get('label') as keyof envProps];
                            if ( featureEnv ){
                                const attributList = getAttribute(featureEnv?.attribute);
                                attributList.forEach((a) => {
                                    f?.setProperties({[a]: undefined});
                                });
                            }
                        }

                        props.newPG(f!,getAccessTokenSilently);

                    }
                }
            }
        });
        setIsDigiOpen(false);
        source?.clear();
      };
    
      const digiCancel = () => {
        setlog && console.log("Measure:React.FC digiCancel");
        setIsDigiOpen(false);
        source?.clear();
      };
    

    const formatLength = useCallback((line:any) => {
        const length = getLength(line);
        let output;
        if (length > 100) {
          output = Math.round((length / 1000) * 100) / 100 + ' km';
        } else {
          output = Math.round(length * 100) / 100 + ' m';
        }
        return output;
    },[]);
      
    const formatArea = useCallback((polygon:any) => {
        const area = getArea(polygon);
        let output;
        if (area > 10000) {
          output = Math.round((area / 1000000) * 100) / 100 + ' km\xB2';
        } else {
          output = Math.round(area * 100) / 100 + ' m\xB2';
        }
        return output;
    },[]);

    const modifyStyle = new Style({
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.7)',
          }),
          fill: new Fill({
            color: 'rgba(0, 0, 0, 0.4)',
          }),
        }),
        text: new Text({
          text: 'Verziehe mich!',
          font: '12px Calibri,sans-serif',
          fill: new Fill({
            color: '#ffa96a',
          }),
          backgroundFill: new Fill({
            color: 'rgba(0, 0, 0, 0.7)',
          }),
          padding: [2, 2, 2, 2],
          textAlign: 'left',
          offsetX: 15,
        }),
    });

    const labelStyle = new Style({
        text: new Text({
          font: '14px Calibri,sans-serif',
          fill: new Fill({
            color: 'rgba(255, 255, 255, 1)',
          }),
          backgroundFill: new Fill({
            color: 'rgba(0, 0, 0, 0.7)',
          }),
          padding: [3, 3, 3, 3],
          textBaseline: 'bottom',
          offsetY: -15,
        }),
        image: new RegularShape({
          radius: 8,
          points: 3,
          angle: Math.PI,
          displacement: [0, 10],
          fill: new Fill({
            color: 'rgba(0, 0, 0, 0.7)',
          }),
        }),
    });
      
    const style = new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
          color: 'rgba(255, 0, 0, 0.5)',
          lineDash: [10, 10],
          width: 5,
        }),
        image: new CircleStyle({
          radius: 5,
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.7)',
          }),
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
        }),
    });

    const segmentStyle = new Style({
        text: new Text({
          font: '12px Calibri,sans-serif',
          fill: new Fill({
            color: 'rgba(255, 255, 255, 1)',
          }),
          backgroundFill: new Fill({
            color: 'rgba(0, 0, 0, 0.4)',
          }),
          padding: [2, 2, 2, 2],
          textBaseline: 'bottom',
          offsetY: -12,
        }),
        image: new RegularShape({
          radius: 6,
          points: 3,
          angle: Math.PI,
          displacement: [0, 8],
          fill: new Fill({
            color: 'rgba(0, 0, 0, 0.4)',
          }),
        }),
    });
      
    const segmentStyles = [segmentStyle];        

    const styleFunction = (feature:Feature, segments:boolean, drawType:string|undefined) => {
        //setlog && console.log("Measure:styleFunction: " + segments);
        //setlog && console.log("Measure:styleFunction: " + drawType);

        const styles = [style];
        const geometry:Geometry|undefined = feature.getGeometry();
        
        let point, label, line;

        if ( geometry ){
            const type:string = geometry.getType();

            if (!drawType || drawType === type) {
                if (type === 'Polygon') {
                    point = (geometry as Polygon).getInteriorPoint();
                    label = formatArea(geometry);
                    line = new LineString( (geometry as Polygon).getCoordinates()[0]);
                } else if (type === 'LineString') {
                    point = new Point( (geometry as LineString).getLastCoordinate());
                    label = formatLength(geometry);
                    line = geometry;
                }
            }

            if ( segments && line ){
                let count = 0;
                (line as LineString).forEachSegment(function (a, b) {
                  const segment = new LineString([a, b]);
                  const label = formatLength(segment);
                  if (segmentStyles.length - 1 < count) {
                    segmentStyles.push(segmentStyle.clone());
                  }
                  const segmentPoint = new Point(segment.getCoordinateAt(0.5));
                  segmentStyles[count].setGeometry(segmentPoint);
                  segmentStyles[count].getText().setText(label);
                  styles.push(segmentStyles[count]);
                  count++;
                });                
            }

            if ( point ){
                labelStyle.setGeometry(point);
                labelStyle.getText().setText(label);
                styles.push(labelStyle);  
            }
        }
        return styles;
    };           

    useEffect(() => {
        setlog && console.log("Measure:React.FC useEffect.[] Intitialisierung");

        const initSource = new OlSourceVector();
        const initVector = new OlVectorLayer({
            source: initSource,
            style: function (feature:FeatureLike) {
                return styleFunction(feature as Feature, showSegments, measureType);
            },        
        });    
        
        initVector.set('name', 'Messen');
        initVector.set('label', 'measure');

        setSource(initSource );
        setVector(initVector);   

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const drawstartFunction = (evt: DrawEvent) => {
        setlog && console.log(evt);
        
        if (clearPrevious) {
            source?.clear();
        }
        modify?.setActive(false);
    };    

    const drawendFunction = (evt: DrawEvent) => {
        setlog && console.log(evt);
        modify?.setActive(changeGeo);
        setIsDigiOpen(digi);
        //forceUpdate();
    };    
    
    const startMeasure = () =>{       
        setlog && console.log('startMeasure');

        if ( !measureOpen ){
            props.clickControl(true);
            setMeasureOpen(true);

            if ( !stayVisible ){
                if ( map ){
                    setlog && console.log("Map:addLayer - Measure")
                    map.addLayer(vector!);
                }
            }
            else{
                vector!.setVisible(true);
            } 
            
            const initModify = new OlModify({
                source: source, 
                style: modifyStyle
            });    
    
            const initDraw = new OlDraw({
                type: measureType,
                source: source,
                style: function (feature:FeatureLike) {
                    return styleFunction(feature as Feature, showSegments, measureType);
                },        
            });
     
            setModify(initModify);
            setDraw(initDraw);
                    
            //map?.un('click',clickEvent);
            //setlog && console.log('not measureOpen');

            initDraw?.on('drawstart', drawstartFunction);
            initDraw?.on('drawend', drawendFunction);            

            map?.addInteraction(initModify!);
            map?.addInteraction(initDraw!);

            const initSnap = new OlSnap({
                source: source, 
            });
            setSnap(initSnap);                
            map?.addInteraction(initSnap!);

        }
        else{
            endMeasure(false);
        }
    }; 

    const endMeasure = (measure:boolean) =>{
        setlog && console.log('endMeasure');
        
        //setlog && console.log('getSource');
        if ( source ){
            if ( clearAll ){
                source.clear();
            }

            if ( !stayVisible ){
                if ( map ){
                    setlog && console.log("Map:removeLayer - Measure")
                    map.removeLayer(vector!);
                } 
            }
            
            map?.removeInteraction(draw!);
            map?.removeInteraction(modify!);
            map?.removeInteraction(snap!);
                     
            //map?.un('pointermove', pointerMoveHandler);
            setMeasureOpen(false);
            props.clickControl(false);            
        }
    };

    const changeDigi = (digi:boolean) =>{
        setlog && console.log('Measure:React.FC changeDigi: ', digi);
        setDigi(digi);
        if ( digi ){
            setClearPrevious(true);
            if ( source ){
                source.clear();
            }            
            map?.getAllLayers().forEach((l) => {
                if ( l.get('label') ){
                    if ( l.get('name') === settings.digi ){
                        setlog && console.log('Measure:React.FC changeDigi: ',l.get('name'));
                        setMeasureType(l.get('type'));
                    }
                }
            });       
        }
    };

    useEffect(() => {
        setlog && console.log("Measure:React.FC useEffect.[settings.digi] "+ settings.digi);
        if ( digi ){
            map?.getAllLayers().forEach((l) => {
                if ( l.get('label') ){
                    if ( l.get('name') === settings.digi ){
                        setlog && console.log('Measure:React.FC useEffect.[settings.digi] ',l.get('name'));
                        setMeasureType(l.get('type'));
                    }
                }
            });       
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings.digi]);

    const changeMeasureType = (type:OlType) => {
        setDigi(false);
        setMeasureType(type);
    };
    
    useEffect(() => {
        //setlog && console.log("Measure:React.FC useEffect.[measureType] "+ measureType);

        draw?.un('drawstart',drawstartFunction);
        draw?.un('drawend', drawendFunction);  
         
        map?.removeInteraction(draw!);
        map?.removeInteraction(snap!);  
        
        const initDraw = new OlDraw({
            type: measureType,
            source: source,
            style: function (feature:FeatureLike) {
                return styleFunction(feature as Feature, showSegments, measureType);
            },        
        });

        initDraw?.on('drawstart', drawstartFunction);
        initDraw?.on('drawend', drawendFunction);   
        
        setDraw(initDraw);
        map?.addInteraction(initDraw!);  

        vector?.setStyle(
            function (feature:FeatureLike) {
                return styleFunction(feature as Feature, showSegments, undefined);
            }
        );

        const initSnap = new OlSnap({
            source: source, 
        });
        setSnap(initSnap);                
        map?.addInteraction(initSnap!);

        // eslint-disable-next-line react-hooks/exhaustive-deps               
    }, [measureType,clearPrevious,digi]);


    useEffect(() => {
        setlog && console.log("Measure:React.FC useEffect.[showSegments]: " + showSegments);
        
        vector?.setStyle(
            function (feature:FeatureLike) {
                return styleFunction(feature as Feature, showSegments, undefined);
            }
        );
        
        draw?.getOverlay().setStyle(
            function (feature:FeatureLike) {
                return styleFunction(feature as Feature, showSegments, measureType);
            }
        );
        
       // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [showSegments]);

    useEffect(() => {
        setlog && console.log("Measure:React.FC useEffect.[changeGeo]: " + changeGeo);
        modify?.setActive(changeGeo);   
        // eslint-disable-next-line react-hooks/exhaustive-deps     
    }, [changeGeo,modify]);

    /*
    useEffect(() => {
        setlog && console.log("Measure:React.FC useEffect.[clearPrevious]: " + clearPrevious);
        draw?.un('drawstart', drawstartFunction);
        draw?.on('drawstart', drawstartFunction);
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [clearPrevious]);    
    */

    useEffect(() => {
        setlog && console.log("Measure:React.FC useEffect.[snapActive]: " + snapActive);
        snap?.setActive(snapActive);
        // eslint-disable-next-line react-hooks/exhaustive-deps         
    }, [snapActive,snap]);    

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    return (
        <div>            
            <SimpleButton
                type="default"
                style={props.style} 
                shape="circle"
                onClick={startMeasure}
                tooltip="Messen"
                tooltipPlacement="right"
                icon={<FormOutlined />}
            />
            <Drawer
                title={"Messen"}
                className="ggw-measure"
                placement="right"
                open={measureOpen}
                onClose={() => {endMeasure(false)}}
                mask={false}		 
            >
                <Form layout="vertical">
                    <Row gutter={[8,16]}>
                        <Col span={24}>
                            <Card title="Geometrie">
                                <Radio.Group onChange={(e) => {changeMeasureType(e.target.value)}} value={measureType}>
                                    <Radio style={radioStyle} value={'Point'} disabled={!isAuthenticated}>Punkt</Radio>
                                    <Radio style={radioStyle} value={'LineString'}>Linie</Radio>
                                    <Radio style={radioStyle} value={'Polygon'}>Polygon</Radio>
                                </Radio.Group>
                            </Card>
                        </Col>
                        { isAuthenticated && (
                            <Col span={24}>
                                <Card title="Digitalisieren">
                                   <Col span={24}>
                                        <Checkbox onChange={(e) => {changeDigi(e.target.checked)}} disabled={settings.digi.length===0} checked={digi}>{settings.digi} erstellen</Checkbox>
                                    </Col>                                                                 
                                </Card>
                            </Col>                                    
                        ) }                        
						<Col span={24}>                            
                            <Card title="Optionen">
                                <Row gutter={[8,8]}>
                                    <Col span={24}>
                                        <Checkbox onChange={(e) => {setShowSegments(e.target.checked)}} checked={showSegments}>Teillängenbeschriftung</Checkbox>
                                    </Col>                                
                                    <Col span={24}>
                                        <Checkbox onChange={(e) => {setChangeGeo(e.target.checked)}} checked={changeGeo}>Geometrien können nachträglich verändert werden</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox onChange={(e) => {setSnapActive(e.target.checked)}} checked={snapActive}>Einrastfunktion</Checkbox>
                                    </Col>                                    
                                    <Col span={24}>
                                        <Checkbox onChange={(e) => {setClearPrevious(e.target.checked)}} checked={clearPrevious}>Letzte Messung löschen</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox onChange={(e) => {setClearAll(e.target.checked)}} checked={clearAll}>Beim Beenden alle Messungen löschen</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox onChange={(e) => {setStayVisible(e.target.checked)}} checked={stayVisible}>Nach dem Beenden bleiben die Messungen sichtbar</Checkbox>
                                    </Col>                                    
                                </Row>                                
                            </Card>

                        </Col>
						<Col span={24}>                            
                            <Button type="link" onClick={() => {endMeasure(false)}} block>Abbruch</Button>
                        </Col>
                    </Row> 
                </Form>            
            </Drawer>
            <Modal 
                title={settings.digi + ' erstellen?'} 
                open={isDigiOpen}
                closable={false} 
                onOk={digiOk} 
                onCancel={digiCancel} 
                okText='Erstellen' 
                cancelText='Abbrechen'>
            </Modal>
        </div>
    );
};

export {Measure};
