import React, { useContext, useState, useReducer } from "react";

import OlLayer from 'ol/layer/Layer';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import 'antd/dist/reset.css';

import { SettingOutlined } from '@ant-design/icons';

import SimpleButton from "./UI/SimpleButton"
import MapContext from "../Map/MapContext";
import {useSettings} from '../Components/SettingsContext';

import { createStringXY as OlcreateStringXY } from 'ol/coordinate';
import OlMousePosition from 'ol/control/MousePosition';

import { useAuth0 } from '@auth0/auth0-react';

import {
    //Button,
    Card,
    Checkbox,
    Col,
    Form,
    Input,
    Radio,
    Row,
    Tooltip, 
	Drawer 
} from 'antd';

import type { RadioChangeEvent } from 'antd';  

interface settingsProps {
    style: React.CSSProperties;
};

const Settings:React.FC<settingsProps> = (props) => {  
    const [ settings, setSettings ] = useState<Boolean>(false);
 
    const openCloseSettings = () =>{       
        //console.log('openSettings');
        setSettings((prevState) => { return !prevState });
    };        
    return (
        <div>            
            <SettingsDrawer open={settings} closeDrawer={openCloseSettings} />
            <SimpleButton
                type="default"
                style={props.style} 
                shape="circle"
                onClick={openCloseSettings}
                tooltip="Einstellungen"
                tooltipPlacement="right"
                icon={<SettingOutlined />}
            />          
        </div>
    );
};

interface settingsDrawerProps {
    open: Boolean;
    closeDrawer: () => void;
};

const SettingsDrawer:React.FC<settingsDrawerProps> = (props) => {
    const setlog = false;
    setlog && console.log("SettingsDrawer:React.FC")
    const map = useContext(MapContext);
    const {settings,setSettings} = useSettings();
    const {
        isAuthenticated
    } = useAuth0(); 	    
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    //setlog && console.log("SettingsDrawer ", map?.getAllLayers());

    const closeDrawer = () =>{
        //setlog && console.log("closeDrawer SettingsDrawer ", props);
        //setlog && console.log("closeDrawer ", settings.mobile);
        setlog && console.log("closeDrawer ", settings.testNum);
        setlog && console.log("closeDrawer ", settings.testStr);
        props.closeDrawer();
    };
    
    const mapFilter : Array<OlLayer> | undefined = map?.getAllLayers().filter((layer) => {
        return layer.get('name').slice(0,1) !== '_';
    });

    const onChangeLayerVisible = (e: CheckboxChangeEvent, layer: OlLayer) => {
        //setlog && console.log('onChangeLayerVisible = ', e.target.checked);
        //setlog && console.log('layer = ', layer);
        layer.setVisible(e.target.checked);
        
        if ( e.target.checked === false ){
            layer.set('select' , false);
            layer.set('digi' , false);
            setSettings({type: 'digi', nextStr: ''})
            setlog && console.log("select aus weil nict sichtbar");
        }
        else {
            if ( layer.get('allow_select') === true){
                layer.set('select' , true);
            }
            if ( layer.get('allow_digi') === true){
                map?.getAllLayers().forEach((l) => {
                    if ( l.get('label') ){
                        if ( layer.get('label') === l.get('label')){
                            setSettings({type: 'digi', nextStr: l.get('name')})
                            l.set('digi' , true);
                        }
                        else{
                            l.set('digi' , false);
                        }
                    }
                });       
            }

        }
        forceUpdate();
    };   

    const onChangeLayerSelect = (e: CheckboxChangeEvent, layer: OlLayer) => {
        //setlog && console.log('onChangeLayerSelect = ', e.target.checked);
        //setlog && console.log('layer = ', layer);
        if ( layer.get('allow_select') === true){
            layer.set('select' , e.target.checked);
            forceUpdate();
        }
    };   

    const onChangeLayerDigi = (e: CheckboxChangeEvent, layer: OlLayer) => {
        //setlog && console.log('onChangeLayerSelect = ', e.target.checked);
        //setlog && console.log('layer = ', layer);

        map?.getAllLayers().forEach((l) => {
            if ( l.get('label') ){
                if ( layer.get('label') === l.get('label')){
                    l.set('digi' ,  true);
                    setSettings({type: 'digi', nextStr: l.get('name')})
                }
                else{
                    l.set('digi' , false);
                }
            }
        });       
        forceUpdate();
    };       

    const setInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        //setlog && console.log('Change:', e.target.value);
        setSettings({type: 'testStr', nextStr: e.target.value})
    };

    const setNumber = (e: RadioChangeEvent) => {
        //setlog && console.log('Change:', e.target.value);
        setSettings({type: 'testNum', nextNum: e.target.value})
    };

    const setProjection = (e: RadioChangeEvent) => {
        //setlog && console.log('Change:', e.target.value);
        setSettings({type: 'projection', nextStr: e.target.value})

        
        const controls = map?.getControls();

        controls?.forEach((control) => {
          if (control instanceof OlMousePosition) {
            map?.removeControl(control);
          }
        });

        map?.addControl(new OlMousePosition({
            coordinateFormat: OlcreateStringXY(e.target.value === 'EPSG:4326' ? 15 : 2),
            projection: e.target.value
        }));
    };    

    return ( 
        <Drawer
            title={"Einstellungen"}
            className="ggw-settings"
            placement="right"
            open={props.open?.valueOf()}
            onClose={closeDrawer}
            mask={false}		
        >
            <Form layout="vertical">
                <Row gutter={[8,8]} >					
                    <Col span={24}>
                        <Card title="Ebenen">                            
                            {mapFilter?.map (layer => 
                                <p key={layer.get('label')}>
                                    <Tooltip placement="top" title="Sichtbar">
                                        <Checkbox checked={layer.getVisible()} onChange = { (e: CheckboxChangeEvent) => onChangeLayerVisible(e,layer)}/>
                                    </Tooltip>
                                    {' '}
                                    <Tooltip placement="top" title="Auswählbar">
                                        <Checkbox disabled={!layer.get('allow_select')} checked={layer.get('select')} onChange = { (e: CheckboxChangeEvent) => onChangeLayerSelect(e,layer)}/>
                                    </Tooltip>
                                    {' '}                                     
                                    { isAuthenticated && (
                                        <Tooltip placement="top" title="Veränderbar">
                                            <Radio disabled={!layer.get('allow_digi')} checked={layer.get('name') === settings.digi} onChange = { (e: CheckboxChangeEvent) => onChangeLayerDigi(e,layer)}/>
                                        </Tooltip>
                                    ) }
                                   
                                    {' - '+layer.get('name')}      
                                </p>    
                            )}
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Card title="Darstellung">
                            <Col span={24}>                           
                                <Checkbox checked={settings.cookies} onChange = { () => setSettings({type: 'cookies'})}>Die App darf Cookies verwenden!</Checkbox>
                            </Col>                            
                            <Col span={24}>                           
                                <Checkbox checked={settings.mobile} onChange = { () => setSettings({type: 'mobile'})}>Optimiert für schmale mobile Geräte</Checkbox>
                            </Col>
                            <Col span={24}>
                                <Radio.Group onChange={setProjection} value={settings.projection}>
                                    <Radio value={'EPSG:25832'}>ETRS89 / UTM zone 32N (EPSG:25832)</Radio>
                                    <Radio value={'EPSG:4326'}>WGS 84 (EPSG:4326)</Radio>
                                </Radio.Group>
                            </Col>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Card title="Antd">
                            <Col span={24}>
                              <Radio.Group onChange={setNumber}>
                                <Radio value={1}>Eins</Radio>
                                <Radio value={2}>Zwei</Radio>
                                <Radio value={3}>Drei</Radio>
                                <Radio value={4}>Vier</Radio>
                            </Radio.Group>
                            </Col>
                            <Col span={24}>
                                <Input  onChange = {setInput} />
                            </Col>                            
                        </Card>
                    </Col>
                </Row>
            </Form>            
        </Drawer>
    );        
};

export {Settings};
//export const MemoizedHthDrawer = React.memo(HthDrawer);