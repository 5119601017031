import { envProps } from './EnvProps';

function getAttribute(input: string | undefined): string[] {
    if ( input ){
        if (input.trim() === '') {
            return [];
        }
        const parts = input.split(',').map((part) => part.trim());
        return parts;
    }
    else return [];
};


/* eslint-disable */ 
const env_freigericht:envProps = {
    printOrientation: 'portrait',
    printScale: 0.5,
    printSize: 'a4',
    printResolution: 72,
    zoom: 14,
    //center : [510000,5552750],
    center : [487210,5554770],
    layers : ['osm','boris','hth','bplan','defi','alkis','dop','tswt'],
    logoPath : './logo_freigericht.png',
    logoType: 'PNG',
    logoSize: [60,15],
    alkis: {
        url:    '/geoserver/freigericht/wms',
        layer:  'freigericht:alkis',
        serverType: 'geoserver'
    },
    dop: {
        url:    '/geoserver/freigericht/wms', 
        layer:  'freigericht:DOP_2018',
        serverType: 'geoserver'
    },     
    tswt: {
        public: true,
        url: 'https://v44172.1blu.de:8085',
        database: 'maintal',
        schema: 'dbo',
        tabelle: 'tswt',
        attribute: 'typ,pate,bemerkung',
        history: false,
        dokumente: true
    },  
    attributions : ['<a href="https://www.freigericht.de/impressum/">Impressum</a> | <a href="https://www.freigericht.de/datenschutz/">Datenschutz</a> | ']
}

const env_maintal:envProps = {
    printOrientation: 'portrait',
    printScale: 0.5,
    printSize: 'a4',
    printResolution: 72,
    zoom: 14,
    center : [487210,5554770],
    layers : ['osm','boris','alkis','dop','tswt'],
    logoPath : './logo_maintal.png',
    logoType: 'PNG',
    logoSize: [60,15],
    alkis: {
        url:    '/geoserver/maintal/wms',
        layer:  'maintal:alkis',
        serverType: 'geoserver'
    },
    dop: {
        url:    '/geoserver/maintal/wms', 
        layer:  'maintal:DOP_2021',
        serverType: 'geoserver'
    },     
    tswt: {
        public: true,
        url: 'https://v44172.1blu.de:8085',
        database: 'maintal',
        schema: 'dbo',
        tabelle: 'tswt',
        attribute: 'typ,pate,bemerkung',
        history: false,
        dokumente: true
    },  
    attributions : ['<a href="https://www.freigericht.de/impressum/">Impressum</a> | <a href="https://www.freigericht.de/datenschutz/">Datenschutz</a> | ']
}
 
const env_erlensee:envProps = {
    printOrientation: 'portrait',
    printScale: 0.5,
    printSize: 'a2',
    printResolution: 72,
    zoom: 15,
    center : [498050,5556870],
    layers : ['osm'],
    logoPath : './logo_erlensee.png',
    logoType: 'PNG',
    logoSize: [51,14],
    alkis: {
        url:    '/geoserver/freigericht/wms', 
        layer:  'freigericht:alkis',
        serverType: 'geoserver'
    }, 
    dop: {
        url:    '/geoserver/freigericht/wms', 
        layer:  'freigericht:DOP_2018',
        serverType: 'geoserver'
    },                
    attributions : ['<a href="https://www.erlensee.de/portal/seiten/Seite-900000001-32880.html">Impressum</a> | <a href="https://www.erlensee.de/portal/seiten/Seite-900000002-32880.html">Datenschutz</a> | ']
} 
 /* eslint-disable */ 

export {env_maintal as env,getAttribute};
//export {env_erlensee as env};