import React, { useEffect, useReducer, useRef, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import Div100vh from 'react-div-100vh'

import OlMap from 'ol/Map';
import OlView from 'ol/View';

import { createStringXY as OlcreateStringXY } from 'ol/coordinate';

import OlScaleLine from 'ol/control/ScaleLine';
import OlMousePosition from 'ol/control/MousePosition';
import { defaults as OldefaultControls, Attribution as OlAttribution } from 'ol/control';
import OlMapBrowserEvent from 'ol/MapBrowserEvent';
import {
    defaults as defaultInteractions,
    DragRotateAndZoom as OlDragRotateAndZoom,
  } from 'ol/interaction';

import OlLayer from 'ol/layer/Layer';
//import OlVectorSource from 'ol/source/Vector';
import OlSourceImageStatic from 'ol/source/ImageStatic';
import OlFeature from 'ol/Feature';
import OlFeatureRenderer from 'ol/render/Feature';
import OlGeometry from 'ol/geom/Geometry';
import GeoJSON from 'ol/format/GeoJSON';
//import WKT from 'ol/format/WKT';

import { osmLayer } from '../Layers/OSM';
import { dopLayer, DOP } from '../Layers/DOP';
import { hthLayer, selecththStyle, HthDrawer } from '../Layers/Hundetuetenhalter';
import { defiLayer, selectdefiStyle, DefiDrawer } from '../Layers/Defibrillator';
import { tswtLayer, selecttswtStyle, TswtDrawer } from '../Layers/Tswt';
import { bplanLayer, selectbplanStyle, BplanDrawer } from '../Layers/BPlan';
import { alkisLayer, AlkisDrawer } from '../Layers/ALKIS';
import { selectLayer } from '../Layers/Select';
import { borisLayer } from '../Layers/Boris';
import { imageLayer } from '../Layers/Image';


//import { envProps } from '../Components/EnvProps';
import { env /*, getAttribute */} from '../Components/Env';
import { Settings } from '../Components/Settings';
import { Home } from '../Components/Home';
import { Logout } from '../Components/Logout';
import { Print } from '../Components/Print';
import { Measure } from '../Components/Measure';
import { Geolocation } from '../Components/Geolocation';

//Proj4
import { register } from 'ol/proj/proj4';
import proj4 from 'proj4';

import './Map.css';
import 'ol/ol.css';

import {
    ConfigProvider,
//    notification,
    theme 
} from 'antd';

import MapContext from './MapContext';
import {useSettings} from '../Components/SettingsContext';

import { useAuth0 } from '@auth0/auth0-react';

import { getAllPG, savePG, deletePG, newPG } from '../Postgres/pg';

proj4.defs('EPSG:25832', '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs');
register(proj4);

const Map: React.FC = () => {
    const setlog = true;
    setlog && console.log("Map:React.FC");
    const mapElement = useRef<HTMLDivElement | null>(null);
    //const mapRef = useRef<OlMap>();

    const [map, setMap] = useState<OlMap>();
    const [feature, setFeature] = useState<OlFeature | null>(null);
    const [searchParams] = useSearchParams();
    const {settings} = useSettings();

    const [ dopAvailable, setDopAvailable ] = useState<Boolean>(false);
    //mapRef.current = map;
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const {
        getAccessTokenSilently,
        isAuthenticated
    } = useAuth0();     

    //setlog && console.log("Map:projection:");
    //setlog && console.log(settings.projection);

    useEffect(() => {
        setlog && console.log("Map:React.FC useEffect.[] Intitialisierung");

        const view = new OlView({
            projection: 'EPSG:25832',
            center: env.center,
            zoom: env.zoom
        });

        const attribution = new OlAttribution({
            tipLabel: 'Nutungsbedigungen',
            collapsible: false
        });

        const scaleLine = new OlScaleLine({ bar: true, text: true, minWidth: 125 });

        const mapT = new OlMap({
            target: mapElement.current!!,
            view: view,
            layers: [],
            controls: OldefaultControls({ attribution: false }).extend([
                attribution,
                scaleLine,
                new OlMousePosition({
                    coordinateFormat: OlcreateStringXY(settings.projection === 'EPSG:4326' ? 15 : 2),
                    projection: settings.projection
                })
            ]),
            interactions: defaultInteractions().extend([
                new OlDragRotateAndZoom(),
            ]),
        });

        const layers = searchParams.get('layers');
        setlog && console.log('Map:layers = ',layers);

        var layersA: string[];
        if (layers != null) {
            layersA = layers.split(",");
            layersA.forEach(function (l) {
                switch (l) {
                    case 'alkis':
                        if (env.layers.indexOf('alkis') > -1) { 
                            alkisLayer.set('load', true);
                            mapT.addLayer(alkisLayer);

                            //gehen wir mal die Datenbank aufwecken
                            const url = alkisLayer.getSource()!.getFeatureInfoUrl(
                                env.center,
                                mapT.getView().getResolution()!,
                                mapT.getView().getProjection()!,
                                {
                                    'INFO_FORMAT' : 'application/json',
                                    'FEATURE_COUNT' : 1, 
                                    'WIDTH': 500, 
                                    'HEIGHT': 500
                                }
                            );	
                            setlog && console.log('fetch',url);
                            if ( url ){            
                                fetch(url).then(response => response.json())
                                    .then((data) => {
                                        //setlog && console.log("alkis ok ", data);
                                                              
                                    })
                                    .catch((error) => {
                                        //setlog && console.error("alkis fehler ", data);
                                        console.error('Fehler:', error);
                                });
                            }
                        };
                        break;
                    case 'boris':
                        if (env.layers.indexOf('boris') > -1) { 
                            mapT.addLayer(borisLayer);
                        };
                        break;            
                    case 'bplan':
                            if (env.layers.indexOf('bplan') > -1) { 
                                mapT.addLayer(bplanLayer);
                            };
                            break;
                    case 'defi':
                        if (env.layers.indexOf('defi') > -1) { 
                            mapT.addLayer(defiLayer);
                        };
                        break;
                    case 'tswt':
                        if (env.layers.indexOf('tswt') > -1) { 
                            if ( env.tswt ){
                                if ( env.tswt.public || isAuthenticated ){
                                    getAllPG(env.tswt.url,env.tswt.database,env.tswt.schema, env.tswt.tabelle, env.tswt.attribute, tswtLayer.getSource()!);
                                    mapT.addLayer(tswtLayer);
                                }
                            }
                        };
                        break;                        
                    case 'dop':
                        if (env.layers.indexOf('dop') > -1) { 
                            mapT.addLayer(dopLayer);
                            setDopAvailable(true);
                        };
                        break;
                    case 'hth':
                        if (env.layers.indexOf('hth') > -1) { 
                            mapT.addLayer(hthLayer);
                        };
                        break;
                    case 'osm':
                        if (env.layers.indexOf('osm') > -1) { 
                            mapT.addLayer(osmLayer);
                        };
                        break;
                    default:
                        break;
                };
            });
        }
        else {
            mapT.addLayer(osmLayer);
        }
        imageLayer.set('name', '_Image');
        mapT.addLayer(imageLayer);

        selectLayer.set('name', '_Select');
        mapT.addLayer(selectLayer);

        //setlog && console.log("setMap");
        setMap(mapT);

        //setlog && console.log('Map:React.FC useEffect.[] - Ende');
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, []);

    const updateSize = useCallback(() => {
        setTimeout(() => {
            setlog && console.log('Map:React.FC useCallback:updateSize.[map]');
            map?.updateSize();
            map?.once('change:size',updateSize);       
        }, 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[map]);

    const clickEvent = useCallback(async (evt: OlMapBrowserEvent<UIEvent>) => {
        setlog && console.log('clickEvent ', evt);

        let lastFeature: OlFeature | null = null;
        let found: boolean = false;
        let printFound: boolean = false;
        let selected: OlFeature[] = [];
        let selectedF: OlFeature[] = [];
        //setlog && console.log(feature);
       
        evt.map!.forEachFeatureAtPixel(evt.pixel, (f: OlFeature<OlGeometry> | OlFeatureRenderer, layer: OlLayer) => {
            setlog && console.log(f);
            if (f instanceof OlFeature<OlGeometry>) {

                if ( layer ){
                    f.set('label', layer.get('label'));
                    setlog && console.log(f.get('label'));
                    if ( f.get('label') === 'print' ){
                        printFound = true;
                    }
                    setlog && console.log('select', layer.get('select'));
                    if ( layer.get('select') === true){
                        if (f !== feature) {
                            setlog && console.log("lastFeature != last");
                            if (found) {
                                setlog && console.log("selectedF");
                                selectedF.push(f);
                            }
                            else {
                                setlog && console.log("selected");
                                selected.push(f);
                            }
                        }
                        else {
                            setlog && console.log("feature == last");
                            found = true;
                        }
                    }
                }
            }
            else {
                setlog && console.log(f);
            }
        });

        if (alkisLayer.get('load') === true && alkisLayer.get('select') === true) {

            selectLayer.getSource()?.clear();

            const url = alkisLayer.getSource()!.getFeatureInfoUrl(
                evt.coordinate,
                evt.map.getView().getResolution()!,
                evt.map.getView().getProjection()!,
                {
                    'INFO_FORMAT' : 'application/json',
                    'FEATURE_COUNT' : 1, 
                    'WIDTH': 500, 
                    'HEIGHT': 500
                }
            );	
            setlog && console.log('fetch',url);
            if ( url ){            
                await fetch(url).then(response => response.json())
                    .then((data) => {
                        
                        if ( data.numberReturned > 0 ){
                            
                            const f:OlFeature =  new GeoJSON().readFeature(data.features[0]);
                            f.set('label', 'alkis');

                            setlog && console.log('alkis:', f);

                            if (f !== feature) {
                                setlog && console.log("lastFeature != last");
                                if (found) {
                                    setlog && console.log("selectedF");
                                    selectedF.push(f);
                                }
                                else {
                                    setlog && console.log("selected");
                                    selected.push(f);
                                }
                            }
                            else {
                                setlog && console.log("feature == last");
                                found = true;
                            }                            
                        } 
                                              
                    })
                    .catch((error) => {console.error('Fehler:', error);
                });
            }
        }

        if ( printFound === false ){
            if (found === false && selected.length === 0 && selectedF.length === 0) {
                feature?.setStyle(undefined);
                setlog && console.log("Nix gefunden");
                setFeature(null);
                imageLayer.setSource(null);
                map?.once('click', clickEvent);
            }
            else {
                if (!found) {
                    lastFeature = selected[0];
                }
                else {
                    if (selectedF.length !== 0) {
                        lastFeature = selectedF[0];
                    } else {
                        if (selected.length !== 0) {
                            lastFeature = selected[0];
                        }
                        else {
                            map?.once('click', clickEvent);
                            return;
                        }
                    }
                }
                feature?.setStyle(undefined);
                if (lastFeature != null) {
                    switch (lastFeature.get('label')) {
                        case 'hth':
                            lastFeature.setStyle(selecththStyle);
                            imageLayer.setSource(null);
                            break;
                        case 'defi':
                            lastFeature.setStyle(selectdefiStyle);
                            imageLayer.setSource(null);
                            break;
                        case 'tswt':
                            lastFeature.setStyle(selecttswtStyle(lastFeature as OlFeature));
                            imageLayer.setSource(null);
                            break;                            
                        case 'bplan':
                            lastFeature.setStyle(selectbplanStyle);
                            var source = new OlSourceImageStatic({
                                url: "./BPlan/" + lastFeature.get('Raster_Raster'),
                                projection: 'EPSG:25832',
                                imageExtent: [lastFeature.get('MinX'), lastFeature.get('MinY'), lastFeature.get('MaxX'), lastFeature.get('MaxY')]
                            });
                            imageLayer.setSource(source);
                            setlog && console.log("./BPlan/" + lastFeature.get('Raster_Raster'));

                            break;
                        case 'alkis':
                            setlog && console.log('alkis');
                            //lastFeature.setStyle(selectalkisStyle);
                            imageLayer.setSource(null);
                            selectLayer.getSource()?.addFeature(lastFeature);
                            
                            break;
                    }
                    setlog && console.log('setFeature: ' + lastFeature?.get('label'));
                    setFeature(lastFeature);
                }
            }
        }
        else {
            setlog && console.log('once again');
            map?.once('click', clickEvent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, feature]);


    useEffect(() => {
        setlog && console.log("Map:React.FC useEffect.[map, clickEvent,updateSize]");
        map?.once('click', clickEvent);
        map?.once('change:size',updateSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, clickEvent,updateSize]);


    const removeFeature = (feature:OlFeature,tabelle:string) => {
        switch (tabelle) {
            case 'hth':
                break;
            case 'defi':
                break;
            case 'tswt':
                tswtLayer.getSource()?.removeFeature(feature!);
                break;                            
            case 'bplan':
                break;
        }
    }                            

    const closeDrawer = (unclick:Boolean=true,action:string='') => {
        setlog && console.log('closeDrawer: ' + feature?.get('label') + ' ' + action);

        if ( isAuthenticated){
            switch (action){
                case 'save':
                    savePG(feature!,getAccessTokenSilently);
                break;
                case 'delete':
                    deletePG(feature!,getAccessTokenSilently,removeFeature);
                break;                
            }
        }

        feature?.setStyle(undefined);
        imageLayer.setSource(null);
        selectLayer.getSource()?.clear();
        setFeature(null);
        if ( unclick ){
            map?.un('click', clickEvent);
        }
    };

    const clickControl = (open:boolean) => {
        if (!open){
            map?.once('click', clickEvent);
            map?.once('change:size',updateSize);            
        }else{
            map?.un('click', clickEvent);
        }
    };    

    return (
        <MapContext.Provider value={map}>
            <ConfigProvider
                theme={{
                    algorithm: theme.compactAlgorithm,    
                    components:  {
                        Drawer: {
                            colorText: '#00b96b', //Überschrift
                            colorBgElevated: '#f0f0f0d3', //Hintergrundfarbe + Transparenz
                        },
                        Input: {
                            colorText: '#ffa96a', //Textfarbe Eingabe
                            colorBgContainer: '#ffffff', //Hintergrund Eingabe
                        },   
                    },
                    token: {
                        colorPrimary: '#ffa96a', //Hintergrund Farbe Button:hoover
                    },                    
                }}
            >
                
                <Div100vh className="map" ref={mapElement}>
                    <DefiDrawer feature={feature} closeDrawer={closeDrawer} />
                    <TswtDrawer feature={feature} closeDrawer={closeDrawer} />
                    <HthDrawer feature={feature} closeDrawer={closeDrawer} />
                    <BplanDrawer feature={feature} closeDrawer={closeDrawer} />
                    <AlkisDrawer feature={feature} closeDrawer={closeDrawer} />
                </Div100vh>

                <Settings style={{ position: 'fixed', top: '100px', left: '11px' }} />
                <Home style={{ position: 'fixed', top: '140px', left: '11px' }} />
                <Print clickControl={clickControl} style={{ position: 'fixed', top: '180px', left: '11px' }}/>
                <Geolocation style={{ position: 'fixed', top: '220px', left: '11px' }}/>
                { dopAvailable === true && (
                    <DOP style={{ position: 'fixed', top: '260px', left: '11px' }} updateMap={forceUpdate}/>
                )}
                <Measure clickControl={clickControl} newPG={newPG} style={{ position: 'fixed', top: '300px', left: '11px' }}/>
                <Logout style={{ position: 'fixed', top: '340px', left: '11px' }} />

                
                
            </ConfigProvider>
        </MapContext.Provider>
    );
}

export default Map;